import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from "react-i18next";


import { Wrap } from './SectionWrap';
import LandingSectionTitle from './LandingSectionTitle';
import LandingSectionSubTitle from './LandingSectionSubTitle';
import { ImageWrap } from './LandingSectionImageWrap';
import LandingSectionButtonWrap from './LandingSectionButtonWrap';
import LandingSectionContents from './LandingSectionContents';
import OutlinedButton from './OutlinedButton';
import useScreenSize from '../../../hooks/useScreenSize';
import SECTION03 from '../../../assets/landing/section3@2x.png';
import { useHistory } from 'react-router-dom';

const Section03 = () => {
    const { t } = useTranslation()
    const history = useHistory();
    const { isMobile } = useScreenSize();
    return (
        <Section mobile={isMobile}>
            <div className="inner">
                <LandingSectionImageWrap mobile={isMobile}>
                    <img
                        src={SECTION03}
                        style={isMobile ? { width: '225px', height: '244px' } : { width: '423px', height: '460px' }}
                        alt="section3"
                    />
                </LandingSectionImageWrap>
                <div>
                    <LandingSectionTitle>{t("landing:section_3_title")}</LandingSectionTitle>
                    <LandingSectionSubTitle>{t("landing:section_3_sub_title")}</LandingSectionSubTitle>
                    <LandingSectionContents>
                        {t("landing:section_3_contents")}
                    </LandingSectionContents>
                    <LandingSectionButtonWrap>
                        <OutlinedButton onClick={() => history.push('/trade')}>{t("landing:start_trading")}</OutlinedButton>
                    </LandingSectionButtonWrap>
                </div>
            </div>
        </Section>
    );
};

export default Section03;

const Section = styled(Wrap)`
    background: #ffffff;
`;

const LandingSectionImageWrap = styled(ImageWrap)`
    margin-right: 140px;
    ${({ mobile }) =>
        mobile &&
        css`
            margin-right: 0;
            & > img {
                position: absolute !important;
                top: unset;
                left: unset;
                transform: unset;
                right: 0;
            }
        `}
`;
