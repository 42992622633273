import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ResetStyle from './styled/ResetStyle';
import theme from './styled/theme';
import { store, persistor } from './states/store';
import {disableReactDevTools}  from './disableReactDevTools';
import swDev from "./swDev"
import "./locales"; 
if (process.env.NODE_ENV === "production") disableReactDevTools();

// @ts-ignore
ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
            <ResetStyle />
            <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
swDev();
