import React, {useCallback} from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";

import Layout from '../../../components/layout';
import Hero from './Hero';
import Reviews from './Reviews';
import Guides from './Guides';
import Section01 from './Section01';
import Section02 from './Section02';
import Section03 from './Section03';
import Section04 from './Section04';
import ContainedButton from './ContainedButton';
import useScreenSize from '../../../hooks/useScreenSize';

const Index = () => {


    return (
        <Layout>
            <Landing>
                <Hero />
                <Section01 />
                <Section02 />
                <Section03 />
                <Section04 />
                <Reviews />
                <Guides />
                <BottomComponent />
            </Landing>
        </Layout>
    );
};

const BottomComponent = () => {
    const history = useHistory();
    const { isMobile } = useScreenSize();
    const { t } = useTranslation()

    return (
        <StartNow mobile={isMobile}>
       <p className="title">{t("landing:start_trading_now")}</p>
        <ContainedButton onClick={() => history.push('/mobile/signup')}>{t("landing:join_now")}</ContainedButton>
        </StartNow>
    )
}

export default Index;

const Landing = styled.div`
    position: relative;
`;

const StartNow = styled.div<{ mobile: boolean }>`
    width: 100%;
    height: 364px;
    padding: 103px 0;
    text-align: center;
    background: #f3f6f8;

    & > .title {
        margin-bottom: 50px;
        font-weight: bold;
        font-size: 40px;
        line-height: 46px;
        color: #000743;
    }
    ${({ mobile }) =>
        mobile &&
        css`
            height: 209px;
            padding: 60px 0;
            .title {
                margin-bottom: 36px;
                font-size: 18px;
                line-height: 21px;
            }
        `}
`;
